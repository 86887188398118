<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />

    <!-- <dashboard-core-settings v-model="expandOnHover" /> -->
    <!-- <base-material-snackbar
      v-if="arrtext.length < 2"
      v-model="snackbar"
      :type="snackbarColor"
      v-bind="{
        timeout: snackbarTimeout,
        top: true,
        right: true
      }"
    >
      <div>{{ snackbarText }}</div>
      <div v-if="showMore" class="mt-2" @click.stop="">
        {{ snackbarMoreText }}
        <div v-if="snackbarMoreLink" class="mt-1">
          <a :href="snackbarMoreLink" class="white--text" target="_blank">
            {{ $t("Mai multe detalii...") }}
          </a>
        </div>
      </div>
      <div v-if="snackbarMore" class="d-flex mt-3">
        <v-spacer />
        <div
          class="text-button"
          style="cursor:pointer"
          @click.stop="
            showMore = !showMore
            snackbarTimeout = 1000000
          "
        >
          {{ showMore ? $t("Închide") : $t("Mai multe...") }}
        </div>
      </div>
    </base-material-snackbar>
    <base-material-snackbar
      v-else
      v-model="snackbar"
      :type="snackbarColor"
      v-bind="{
        timeout: snackbarTimeout,
        top: true,
        right: true
      }"
    >
      <div v-for="(msg, ind) in arrtext" :key="ind">{{ msg }}</div>
    </base-material-snackbar> -->
    <s-snackbars :objects.sync="objects" top right>
      <template v-slot:default="{ message }">
        <div class="text-h4">
          <div v-if="message.msgs.length > 1">
            <div v-for="(msg, ind) in message.msgs" :key="ind" v-html="msg"></div>
          </div>
          <div v-else v-html="message.msg"></div>
          <div v-if="message.moreText" class="d-flex mt-3">
            <v-spacer />
            <div class="text-button" style="cursor:pointer" @click.stop="showMore(message)">
              {{ $t("Mai multe...") }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:action="{ close }">
        <v-btn text icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
      </template>
    </s-snackbars>
    <v-dialog v-model="more" max-width="290">
      <v-card>
        <v-card-text>
          <div v-html="moreText"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="moreLink" color="primary" text @click="openLink">{{ $t("Mai multe...") }}</v-btn>
          <v-btn color="primary" text @click="more = false">{{ $t("Închide") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { EventBus } from "../../EventBus"
import SSnackbars from "v-snackbars"
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    SSnackbars,
    // DashboardCoreSettings: () => import("./components/core/Settings"),
    DashboardCoreView: () => import("./components/core/View")
  },
  beforeRouteEnter(to, from, next) {
    //this.$log("beforeRouteEnter index dash ", localStorage.accountToken)
    if (localStorage.accountToken || sessionStorage.accountToken) {
      next()
    } else {
      let str = ""
      if (to.query) {
        str = "?"
        for (const x in to.query) {
          str += "&" + x + "=" + to.query[x]
        }
      }
      next("/autentificare" + str)
    }
  },
  data: () => ({
    objects: [],
    more: false,
    moreText: "",
    moreLink: "",
    expandOnHover: false
  }),
  computed: {
    accountToken() {
      return this.$store.getters.accountToken
    }
  },
  watch: {
    accountToken(val) {
      this.$log("login userKey change ")
      if (!val) {
        this.$router.push("/autentificare")
      }
    }
  },
  created() {
    //this.$log("createdcreated ", this.$store.getters.darkMode)
    this.$i18n.locale = this.$store.getters.language
    this.$vuetify.theme.dark = this.$store.getters.darkMode
    //this.$log("settingssssssssssssss3 ", this.$store.getters.primaryColor)
    this.$vuetify.theme.themes.dark.primary = this.$store.getters.primaryColorDark
    this.$vuetify.theme.themes.light.primary = this.$store.getters.primaryColor

    EventBus.$on("show-alert", salert => {
      this.$log("alertttttttttttttttttttttt app", this.objects)
      let moreText = ""
      let moreLink = ""
      if (salert.more) {
        if (salert.more.more_info) {
          moreText = salert.more.more_info
          moreLink = salert.more.doc_url
        }
      }
      this.objects.push({
        message: { msg: salert.message, msgs: salert.message.split("###"), moreText, moreLink },
        color: salert.color ? salert.color : "success",
        timeout: salert.timeOut ? salert.timeOut : 5000
      })
      // this.snackbarColor = salert.color ? salert.color : "info"
      // this.snackbarTimeout = salert.timeOut ? salert.timeOut : 5000
      // this.arrtext = salert.message.split("###")
      // this.snackbarText = salert.message
      // this.snackbarMore = false
      // this.showMore = false
      // if (salert.more) {
      //   if (salert.more.more_info) {
      //     this.snackbarMore = true
      //     this.snackbarMoreText = salert.more.more_info
      //     this.snackbarMoreLink = salert.more.doc_url
      //   }
      // }
      // this.snackbar = true
    })
  },
  methods: {
    openLink() {
      window.open(this.moreLink)
    },
    showMore(msg) {
      this.$log("showMore ", msg)
      window.open(msg.moreLink)
      // this.moreText = msg.moreText
      // this.moreLink = msg.moreLink
      // this.more = true
    }
  }
}
</script>
<!--style lang="sass">

::-webkit-scrollbar
  width: 10px
  height: 10px

::-webkit-scrollbar-track
  background: #f5f5f5

::-webkit-scrollbar-thumb
  background: #b0b0b0

.theme--light ::-webkit-scrollbar
  width: 10px
  height: 10px

.theme--light ::-webkit-scrollbar-track
  background: #f5f5f5

.theme--light ::-webkit-scrollbar-thumb
  background: #b0b0b0

.theme--light ::-webkit-scrollbar-corner
  background: #f5f5f5

.theme--dark ::-webkit-scrollbar
  width: 10px
  height: 10px

.theme--dark ::-webkit-scrollbar-track
  background: #202020

.theme--dark ::-webkit-scrollbar-thumb
  background: #3e3e3e

.theme--dark ::-webkit-scrollbar-corner
  background: #202020
</style-->
